<template>
  <div>
    <v-subheader v-if="!kpis || !kpis.length">
      As estatísticas da sua loja não foram processadas ainda, volte daqui
      algumas horas.
    </v-subheader>
    <Grid className="grid-kpi-simple">
      <Card
        class="kpi-container"
        v-for="(k, i) in kpisSimple"
        :key="i"
        :ref="k.idkpifornecedor"
      >
        <div style="padding-bottom:50px">
          <v-subheader style="height:unset;">
            <h3 style="width:100%">{{ k.nome }}</h3>
          </v-subheader>
          <div v-if="k.metadados" class="kpi-chart-container">
            <div
              class="kpi-chart-number"
              v-if="k.metadados.tipoExibicao === 'number'"
            >
              <span
                class="kpi-chart-number-value"
                v-if="k.metadados && k.metadados.formatador === '$'"
              >
                {{ k.valor | formatMoney() }}
              </span>
              <span
                class="kpi-chart-number-value"
                v-else-if="k.metadados && k.metadados.formatador === 'hs'"
              >
                {{ k.valor | formatPrecision(0) }} horas
              </span>
              <span class="kpi-chart-number-value" v-else>
                {{ k.valor | formatPrecision(0) }}
              </span>
            </div>
          </div>
        </div>
        <div class="kpi-footer">
          <div class="kpi-tags" v-if="k.metadados">
            <span v-for="t in k.metadados.tags" :key="t">
              <Chip v-if="t" color="orange" :text="t" :small="true" />
            </span>
          </div>
          <div class="kpi-timeline">
            <span class="last">
              Atualizado {{ k.datacriacao | formatDateTime(getTimeZone) }}
            </span>
            <span class="next">
              Prox. atualização
              {{ k.dataatualizacao | formatDateTime(getTimeZone) }}
            </span>
          </div>
        </div>
      </Card>
    </Grid>
    <Grid className="grid-kpi-multi">
      <Card
        class="kpi-container multi"
        v-for="(k, i) in filteredData"
        :key="i"
        :ref="k.idkpifornecedor"
      >
        <div class="kpi-header">
          <FormSelect
            v-if="
              k.valor_multiplo &&
                k.valor_multiplo.dados &&
                !k.metadados.esconderSelecao
            "
            label="Tipo"
            style="max-width:95px"
            v-model="k.metadados.tipoExibicao"
            :options="kpiTypesMulti"
          />
          <v-subheader class="kpi-chart-title">
            <h3 style="width:100%">{{ k.nome }}</h3>
          </v-subheader>
        </div>
        <div class="kpi-chart">
          <div
            class="kpi-chart-container-info"
            v-if="k.valor_multiplo && k.valor_multiplo.dados"
          >
            <div
              style="overflow-y: auto; max-height: 300px;"
              v-if="k && k.metadados && k.metadados.tipoExibicao === 'list'"
            >
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        <span>{{ k.valor_multiplo.campoLabel }}</span>
                      </th>
                      <th class="text-center">
                        <span>{{ k.valor_multiplo.valorLabel }}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(val, y) in k.valor_multiplo.dados" :key="y">
                      <td class="text-left">
                        <span v-if="k.metadados.formatadorCampo === 'date'">
                          {{ val.campo | formatDate() }}
                        </span>
                        <span v-else>
                          {{ val.campo }}
                        </span>
                      </td>
                      <td class="text-center">
                        <span v-if="k.metadados.formatador === '$'">
                          {{ val.valor | formatMoney() }}
                        </span>
                        <span v-else>
                          {{ val.valor | formatPrecision(0) }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
            <div v-else>
              <apexchart
                width="300"
                height="200"
                :type="(k && k.metadados && k.metadados.tipoExibicao) || 'line'"
                :options="getChartOptions(k)"
                :series="getChartSeries(k)"
              >
              </apexchart>
            </div>
          </div>
        </div>
        <div class="kpi-footer">
          <div class="kpi-tags" v-if="k.metadados">
            <span v-for="t in k.metadados.tags" :key="t">
              <Chip v-if="t" color="orange" :text="t" :small="true" />
            </span>
          </div>
          <div class="kpi-timeline">
            <span class="last">
              Atualizado {{ k.datacriacao | formatDateTime(getTimeZone) }}
            </span>
            <span class="next">
              Prox. atualização
              {{ k.dataatualizacao | formatDateTime(getTimeZone) }}
            </span>
          </div>
        </div>
      </Card>
    </Grid>
  </div>
</template>

<script>
import FormSelect from '@/components/form/FormSelect'
import VueApexCharts from 'vue-apexcharts'
import Card from '@/components/Card'
import Chip from '@/components/Chip'
import Grid from '@/components/Grid'
import { mapGetters } from 'vuex'
import * as moment from 'moment'
import * as _ from 'lodash'

export default {
  props: {
    kpis: Array,
    filter: Object
  },
  name: 'KpisFornecedor',
  components: {
    apexchart: VueApexCharts,
    FormSelect,
    Grid,
    Card,
    Chip
  },
  data: () => ({
    filteredData: []
  }),
  watch: {
    'filter.search'(newVal, oldVal) {
      if (newVal != oldVal && newVal) {
        this.$emit('change-search', false)
        this.filteredData = this.getFilteredData(this.kpisMultiple)
      }
    }
  },
  computed: {
    ...mapGetters(['getTimeZone']),
    kpiTypesMulti() {
      return [
        {
          text: 'Lista',
          value: 'list',
          icon: 'fas fa-list'
        },
        {
          text: 'Gráfico de linha',
          value: 'line',
          icon: 'fas fa-chart-line'
        },
        {
          text: 'Gráfico de barras',
          value: 'bar',
          icon: 'fas fa-chart-bar'
        },
        {
          text: 'Gráfico de pizza',
          value: 'pie',
          icon: 'fas fa-chart-pie'
        },
        {
          text: 'Gráfico de donut',
          value: 'donut',
          icon: 'far fa-circle'
        }
      ]
    },
    kpisSimple() {
      return (this.kpis || []).filter(k => k.tipo === 'SIMPLES')
    },
    kpisMultiple() {
      return (this.kpis || []).filter(k => k.tipo === 'MULTIPLO')
    }
  },
  mounted() {
    this.filteredData = this.getFilteredData(this.kpisMultiple)
  },
  methods: {
    getFilteredData(data) {
      return data.map(k => {
        const {
          metadados: { canFilter },
          valor_multiplo
        } = k
        const data = _.get(valor_multiplo, 'dados', [])

        const _d = canFilter
          ? data.filter(d => {
              const isG = d.campo >= this.filter.initDate
              const isL = d.campo <= this.filter.finalDate
              const isTrue = isG && isL
              return isTrue
            })
          : data
        const val = { ...k.valor_multiplo, dados: _d }
        return { ...k, valor_multiplo: val }
      })
    },
    getChartOptions(k) {
      const formatadorCampo = _.get(k, 'metadados.formatadorCampo') || ''
      const categories = _.get(k, 'valor_multiplo.dados', []).map(d =>
        formatadorCampo === 'date'
          ? moment(d.campo).format('DD/MM/YYYY')
          : d.campo
      )
      const type = _.get(k, 'metadados.tipoExibicao') || 'line'
      if (type === 'pie' || type === 'donut') {
        return { labels: categories }
      }
      return {
        chart: {
          height: 200,
          type,
          stacked: false
        },
        dataLabels: {
          // enabled: false,
        },
        xaxis: { categories },
        yaxis: [
          {
            axisTicks: {
              show: false
            },
            axisBorder: {
              show: true
            },
            title: {
              text: k.valor_multiplo.valorLabel
            }
            /* labels: {
              formatter: function (value) {
                return value + "$";
              }
            } */
          }
        ],
        tooltip: {
          shared: false,
          intersect: false,
          x: {
            show: true
          }
        }
      }
    },
    getChartSeries(k) {
      const type = _.get(k, 'metadados.tipoExibicao') || 'line'
      const dados = _.get(k, 'valor_multiplo.dados' || [])
      const name = _.get(k, 'valor_multiplo.valorLabel' || '')
      if (type === 'pie' || type === 'donut') {
        const total = dados
          .map(d => d.valor)
          .reduce((a, b) => Number(a) + Number(b), 0)
        return dados.map(d => (d.valor / total) * 100)
      }
      return [
        {
          name,
          data: (dados || []).map(d => d.valor)
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.grid-kpi-simple,
.grid-kpi-multi {
  grid-gap: 0px 10px !important;
}
.grid-kpi-simple {
  grid-template-columns: repeat(3, 1fr) !important;
}

.grid-kpi-multi {
  grid-template-columns: repeat(3, auto) !important;
}

@media (min-width: 1780px) {
  .grid-kpi-multi {
    grid-template-columns: repeat(4, auto) !important;
  }
  .grid-kpi-simple {
    grid-template-columns: repeat(4, 1fr) !important;
  }
}
@media (max-width: 1480px) {
  .grid-kpi-multi {
    grid-template-columns: repeat(3, auto) !important;
  }
  .grid-kpi-simple {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}
@media (max-width: 1218px) {
  .grid-kpi-simple {
    grid-template-columns: repeat(2, fr) !important;
  }
  .grid-kpi-multi {
    grid-template-columns: repeat(2, auto) !important;
  }
}
.apexcharts-canvas {
  width: 100% !important;
  min-height: 200px !important;
}
.kpi-container.multi {
  height: 96%;
  min-height: 369px;
  .content-card {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .kpi-chart {
    flex: 1;
    min-height: 300px;
    .kpi-chart-container-info {
      padding-bottom: 15px;
      margin-bottom: 10px;
      max-height: 352px;
      overflow: auto;
    }
  }
}
.kpi-container {
  display: flex;
  place-items: center;
  flex-direction: column;
  width: auto;
  min-width: 200px;
  max-width: 330px;
  align-self: start;
  transition: all 0.3s ease-in-out;
  margin: 5px;
  .kpi-chart-title {
    height: 15px;
    padding-bottom: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .kpi-header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
  }
  .kpi-chart-container {
    width: 100% !important;
    overflow: auto;
    transition: all 0.3s ease-in-out;
    .kpi-chart-number {
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      .kpi-chart-number-value {
        font-size: 2rem;
      }
    }
  }
  .kpi-footer {
    position: absolute;
    bottom: 0;
    width: 93%;
    background-color: white;
    .kpi-tags {
      font-size: 0.9rem;
      text-align: right;
    }
    .kpi-timeline {
      font-size: 0.7rem;
      display: flex;
      justify-content: space-between;
      .last,
      .next {
        color: gray;
      }
    }
  }
  div.content-card {
    padding: 10px !important;
    width: 100%;
    max-height: 400px;
    overflow: hidden;
  }
  .panel-options {
    margin-bottom: 5px !important;
  }
}
</style>
