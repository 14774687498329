<template>
  <div v-if="barChartOptions" style="position: unset;">
    <h3>{{ label }}</h3>
    <VueApexCharts
      name="name"
      type="bar"
      width="100%"
      height="350px"
      :options="barChartOptions"
      :series="series"
    >
    </VueApexCharts>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'

export default {
  name: 'MultiBar',
  components: {
    VueApexCharts
  },
  props: {
    label: {
      type: String
    },
    name: {
      type: String
    },
    xAxisLabel: {
      type: String
    },
    yAxisLabel: {
      type: String
    },
    datum: {
      type: Array
    }
  },
  data: function() {
    return {
      series: null,
      barChartOptions: null
    }
  },
  created() {
    this.series = [
      {
        name: this.datum[0].key,
        data: this.datum[0].values,
        color: this.datum[0].color
      },
      {
        name: this.datum[1].key,
        data: this.datum[1].values,
        color: this.datum[1].color
      }
    ]

    this.barChartOptions = {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: false,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        tickAmount: 6,
        labels: {
          formatter: function(val) {
            if (val.length > 7) {
              const splitDate = val.split('/')
              const date =
                '20' + splitDate[2] + '-' + splitDate[1] + '-' + splitDate[0]
              return moment(new Date(date)).format('MM/YYYY')
            }
          }
        }
      },
      yaxis: {
        title: {
          text: this.yAxisLabel
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return val
          }
        }
      }
    }
  },
  methods: {
    formatValue(value, max) {
      let valor_formatado = value
      valor_formatado = (value * 100) / max
      return valor_formatado
    }
  }
}
</script>
