<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="row" v-else-if="showModuloForm === true">
    <div
      :class="
        show.detalhesRuptura ? 'page col-md-9 center' : 'page col-md-12 center'
      "
    >
      <div>
        <Panel v-if="isLoading" lineColor="var(--primary-color)">
          <Spinner />
        </Panel>

        <Tabs
          v-if="!isLoading"
          :tabs="tabs"
          color="var(--primary-2-color)"
          :start="tabStart"
        >
          <template v-slot:dashboard>
            <div class="filter-container">
              <div
                class="row"
                v-if="
                  !loading.ruptura &&
                    !loading.avaliacao_media &&
                    !loading.tempo_medio
                "
              >
                <div class="col-md-4 center">
                  <RadialBar
                    :name="'indice_ruptura'"
                    :value="ruptura.valor"
                    :label="'Índice de ruptura'"
                    :value_text="'%'"
                    :min="0"
                    :max="100"
                    :limit1="0.08"
                    :limit2="0.16"
                    :colors="colors.gauge.smallBetter"
                  />

                  <FormButton
                    text="Detalhes"
                    type="primario"
                    @click="detalhesRuptura"
                  />
                </div>

                <div class="col-md-4 center">
                  <RadialBar
                    :name="'avaliacao_media'"
                    :value="avaliacaoMedia.valor"
                    :label="'Avaliação Média'"
                    :value_text="'*'"
                    :min="0"
                    :max="5"
                    :limit1="7.5 / 10"
                    :limit2="8.7 / 10"
                    :colors="colors.gauge.bigBetter"
                  />
                </div>

                <div class="col-md-4 center">
                  <RadialBar
                    :name="'tempo_atendimento'"
                    :value="Number(tempoAtendimentoMedio.valor)"
                    :label="'Tempo médio de atendimento'"
                    :value_text="tempoAtendimentoMedio.texto"
                    :min="0"
                    :max="24"
                    :limit1="2 / 24"
                    :limit2="4 / 24"
                    :colors="colors.gauge.smallBetter"
                  />
                </div>
              </div>
            </div>

            <div class="filter-container" v-if="!loading.ticket_medio">
              <div
                class="row"
                v-if="
                  !loading.ruptura &&
                    !loading.avaliacao_media &&
                    !loading.tempo_medio
                "
              >
                <div class="col-md-12 center">
                  <MultiBar
                    :name="'ticket_medio'"
                    :label="'Ticket Médio'"
                    :x-axis-label="ticket.xAxisLabel"
                    :y-axis-label="ticket.yAxisLabel"
                    :datum="ticket.datum"
                  />
                </div>
                <div class="col-md-12 center">
                  <MultiBar
                    :name="'total_pedidos'"
                    :label="'Total de pedidos'"
                    :x-axis-label="totalPedidos.xAxisLabel"
                    :y-axis-label="totalPedidos.yAxisLabel"
                    :datum="totalPedidos.datum"
                  />
                </div>
              </div>
            </div>
          </template>
          <template v-slot:estatisticas>
            <div v-if="!loading.kpi" :kpis="kpis" class="filter-container">
              <FormDatePicker
                v-model="filter.initDate"
                label="Data inicial"
                :min="filter.startingDate"
                :max="filter.finalDate"
              />
              <FormDatePicker
                v-model="filter.finalDate"
                :min="filter.startingDate"
                label="Data final"
              />
              <div>
                <FormButton
                  text="Buscar"
                  type="primario"
                  @click="filter.search = true"
                />
              </div>
            </div>
            <KpisFornecedor
              v-if="!loading.kpi"
              :kpis="kpis"
              :filter="filter"
              @change-search="val => (filter.search = val)"
            />
          </template>
        </Tabs>
      </div>
    </div>
    <div class="page col-md-3" v-if="show.detalhesRuptura">
      <div>
        <Panel lineColor="var(--primary-color)">
          <div class="panel-body">
            <span v-if="show.detalhesRuptura">
              <p>
                Esses são os produtos onde ocorreu ruptura nos seus últimos 100
                pedidos
              </p>

              <div v-if="ruptura.detalhes && ruptura.detalhes.produtos">
                <DataTable
                  :headers="headers_produtos"
                  :data="ruptura.detalhes.produtos"
                  :itemsPerPage="30"
                  sortBy="produto"
                  :sortDesc="false"
                  ref="datatable_produtos"
                  :auto-search="false"
                >
                  <template v-slot:idpedido="{ col }">
                    {{ col.nome }}
                  </template>
                  <template v-slot:datapedido="{ col }">
                    {{ col.ruptura_financeira_produto.toFixed(2) }}
                  </template>
                </DataTable>
              </div>

              <a
                class="btn btn-sm pull-right"
                @click="show.detalhesRuptura = false"
                >Fechar</a
              >
            </span>
          </div>
        </Panel>
      </div>
    </div>
  </div>
</template>

<script>
import { DIAGNOSTICO_LOAD, KPI_LOAD } from '@/store/actions/diagnostico'
import FormDatePicker from '@/components/form/FormDatePicker'
import FormButton from '@/components/form/FormButton'
import Panel from '@/components/Panel'
import Tabs from '@/components/Tabs'
import Spinner from '@/components/Spinner'
import KpisFornecedor from '@/components/KpisFornecedor'
import { mapGetters } from 'vuex'
import * as _ from 'lodash'
import * as moment from 'moment'
import MarketingModules from '@/components/MarketingModules'
import { ModulesEnum } from '@/store/actions/modulesEnum'
import {
  AVALIACAO_LOAD,
  RUPTURA_DETALHE,
  RUPTURA_LOAD,
  TEMPO_MEDIO_LOAD,
  TICKET_MEDIO_LOAD
} from '../store/actions/diagnostico'
// import VueApexCharts from 'vue-apexcharts'
import DataTable from '@/components/DataTable'
import RadialBar from '../components/charts/RadialBar.vue'
import MultiBar from '../components/charts/MultiBar.vue'

export default {
  name: 'Dashboard',
  components: {
    // VueApexCharts,
    FormDatePicker,
    KpisFornecedor,
    FormButton,
    Spinner,
    Panel,
    Tabs,
    MarketingModules,
    DataTable,
    RadialBar,
    MultiBar
  },
  data: () => ({
    menu: {},
    diagnoses: [],
    kpis: [],
    totalConclusao: 0,
    loading: {
      kpi: true,
      diagnoses: true,
      ruptura: true,
      avaliacao_media: true,
      tempo_medio: true,
      ticket_medio: true
    },
    active: null,
    filter: {
      startingDate: moment()
        .add(-90, 'days')
        .format('YYYY-MM-DD'),
      initDate: moment()
        .add(-90, 'days')
        .format('YYYY-MM-DD'),
      finalDate: moment().format('YYYY-MM-DD'),
      formattedInitDate: moment()
        .add(-90, 'days')
        .format('DD/MM/YYYY'),
      formattedFinalDate: moment().format('DD/MM/YYYY'),
      // nowDate: new Date().toISOString().substr(0, 10),
      search: false
    },
    tabs: [
      {
        key: 'dashboard',
        text: 'Dashboard'
      },
      {
        key: 'estatisticas',
        text: 'Estatísticas'
      }
    ],
    tabStart: 0,
    modulosForm: [ModulesEnum.TODOS],
    ruptura: {
      valor: -1
    },
    avaliacaoMedia: {
      valor: 0
    },
    tempoAtendimentoMedio: {
      valor: 0
    },
    ticket: {
      xAxisLabel: 'Data',
      yAxisLabel: 'Ticket médio',
      datum: []
    },
    totalPedidos: {
      xAxisLabel: 'Data',
      yAxisLabel: 'Total de pedidos',
      datum: []
    },
    colors: {
      gauge: {
        smallBetter: ['#63DF67', '#F6DD0C', '#DF634F'],
        bigBetter: ['#DF634F', '#F6DD0C', '#63DF67']
      }
    },
    rupturaChartOptions: {
      chart: {
        height: 350,
        type: 'radialBar',
        offsetY: -10
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          dataLabels: {
            name: {
              offsetY: -10,
              show: true,
              color: '#888',
              fontSize: '17px'
            },
            value: {
              formatter: function(val) {
                return parseInt(val)
              },
              color: '#111',
              fontSize: '36px'
            }
          }
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0,
          gradientToColors: ['#63DF67', '#F6DD0C', '#DF634F'],
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        }
      },
      stroke: {
        lineCap: 'round'
      },
      labels: ['%']
    },
    avaliacaoChartOptions: {
      chart: {
        height: 350,
        type: 'radialBar',
        offsetY: -10
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          hollow: {
            margin: 5,
            size: '50%'
          },
          dataLabels: {
            name: {
              offsetY: -10,
              show: true,
              color: '#888',
              fontSize: '17px'
            },
            value: {
              formatter: function(val) {
                return parseFloat(val / 20).toFixed(2)
              },
              color: '#111',
              fontSize: '36px'
            }
          }
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0,
          gradientToColors: ['#63DF67', '#F6DD0C', '#DF634F'],
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        }
      },
      stroke: {
        lineCap: 'round'
      },
      labels: ['*']
    },
    tempoMedioChartOptions: {
      chart: {
        height: 350,
        type: 'radialBar',
        offsetY: -10
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          dataLabels: {
            name: {
              offsetY: -10,
              show: true,
              color: '#888',
              fontSize: '17px'
            },
            value: {
              formatter: function(val) {
                return (parseFloat(val) / 4.16).toFixed(2)
              },
              color: '#111',
              fontSize: '36px'
            }
          }
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0,
          gradientToColors: ['#63DF67', '#F6DD0C', '#DF634F'],
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        }
      },
      stroke: {
        lineCap: 'round'
      },
      labels: ['%']
    },
    show: {
      detalhesRuptura: false
    },
    headers_produtos: [
      { text: 'Produto', value: 'nome' },
      { text: 'Ruptura (R$)', value: 'ruptura_financeira_produto' }
    ]
  }),
  created() {
    this.getKpis()
    this.getDignoses()
    this.getIndiceRuptura()
    this.getAvaliacaoMedia()
    this.getTempoMedioAtendimento()
    this.getTicketMedio()
  },
  computed: {
    ...mapGetters([
      'getEmpresaId',
      'getFornecedorId',
      'getFornecedorTag',
      'moduloFornecedorAtivo'
    ]),
    endpoint() {
      return `/api/v4/report/datatable/${this.getFornecedorId}`
    },
    isLoading() {
      return this.loading.kpi && this.loading.diagnoses
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  watch: {
    'filter.initDate'(val) {
      this.filter.formattedInitDate = moment(val).format('DD/MM/YYYY')
      // if(val) this.getKpis()
    },
    'filter.finalDate'(val) {
      this.filter.formattedFinalDate = moment(val).format('DD/MM/YYYY')
      // if(val) this.getKpis()
    }
  },
  methods: {
    selectTab(val) {
      this.tabStart = val
    },
    getDignoses() {
      this.$store
        .dispatch(DIAGNOSTICO_LOAD)
        .then(result => {
          if (result) {
            this.diagnoses = result.data
            this.totalConclusao = result.totalConclusao
          }
          this.loading.diagnoses = false
        })
        .catch(() => {
          this.loading.diagnoses = false
        })
    },
    getKpis() {
      this.$store
        .dispatch(KPI_LOAD)
        .then(result => {
          if (result) {
            this.kpis = result || []
          }
          this.loading.kpi = false
        })
        .catch(() => {
          this.loading.kpi = false
        })
    },
    scrollTo(id) {
      const el = this.$refs[id]
      const element = _.get(el, '0.$el')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    },
    getAvaliacaoMedia() {
      this.$store
        .dispatch(AVALIACAO_LOAD)
        .then(result => {
          if (result) {
            this.avaliacaoMedia.valor = result.notaavaliacao
          }
          this.loading.avaliacao_media = false
        })
        .catch(() => {
          this.loading.avaliacao_media = false
        })
    },
    getIndiceRuptura() {
      this.$store
        .dispatch(RUPTURA_LOAD)
        .then(result => {
          if (result) {
            if (result.total_ruptura > 0 && result.total_vendido > 0) {
              this.ruptura.valor = Number(
                (
                  Number(result.total_ruptura / result.total_vendido) * 100
                ).toFixed(2)
              )
            } else {
              this.ruptura.valor = 0
            }
          }
          this.loading.ruptura = false
        })
        .catch(() => {
          this.loading.ruptura = false
        })
    },
    getTempoMedioAtendimento() {
      this.$store
        .dispatch(TEMPO_MEDIO_LOAD)
        .then(result => {
          if (result) {
            this.tempoAtendimentoMedio.valor =
              result.tempo_medio_atendimento_horas
            this.tempoAtendimentoMedio.texto =
              result.tempo_medio_atendimento_text
          }
          this.loading.tempo_medio = false
        })
        .catch(() => {
          this.loading.tempo_medio = false
        })
    },
    getTicketMedio() {
      this.$store
        .dispatch(TICKET_MEDIO_LOAD)
        .then(result => {
          if (result) {
            var datumValuesTicket = []
            var datumValuesCancel = []
            var datumValuesNumPedidos = []
            var datumValCountCancels = []

            result.map(item => {
              datumValuesTicket.push(
                this.datumItem(Number(item.ticket_medio), item.dia)
              )
              datumValuesNumPedidos.push(
                this.datumItem(Number(item.count_pedidos), item.dia)
              )
              datumValuesCancel.push(
                this.datumItem(Number(item.valor_cancelado_medio), item.dia)
              )
              datumValCountCancels.push(
                this.datumItem(Number(item.count_pedidos_cancelados), item.dia)
              )
            })

            this.ticket.datum = [
              {
                values: datumValuesTicket,
                key: 'Ticket médio (R$)',
                color: '#98df8a'
              },
              {
                values: datumValuesCancel,
                key: 'Cancel. médio (R$)',
                color: '#ec9884'
              }
            ]

            this.totalPedidos.datum = [
              {
                values: datumValuesNumPedidos,
                key: 'Num. pedidos',
                color: '#98df8a'
              },
              {
                values: datumValCountCancels,
                key: 'Num. cancelamentos',
                color: '#ec9884'
              }
            ]
          }
          this.loading.ticket_medio = false
        })
        .catch(() => {
          this.loading.ticket_medio = false
        })
    },
    detalhesRuptura() {
      this.$store
        .dispatch(RUPTURA_DETALHE)
        .then(result => {
          if (result) {
            this.ruptura.detalhes = {
              produtos: result.produtos_mais_rompidos
            }
          }
          this.show.detalhesRuptura = true
        })
        .catch(() => {
          this.show.detalhesRuptura = false
        })
    },
    datumItem(y, label) {
      return {
        x: label,
        y: y
      }
    }
  }
}
</script>

<style lang="scss">
.filter-container {
  display: flex;
  align-items: baseline;
  div {
    padding: 5px;
  }
}
.row {
  width: 100%;
}
</style>
