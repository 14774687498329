<template>
  <div v-if="radialChartOptions">
    <h3>{{ label }}</h3>
    <VueApexCharts
      name="name"
      type="radialBar"
      width="300"
      :options="radialChartOptions"
      :series="[formatValue(value, max)]"
    >
    </VueApexCharts>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'RadialBar',
  components: {
    VueApexCharts
  },
  props: {
    value: {
      type: Number
    },
    label: {
      type: String
    },
    value_text: {
      type: String
    },
    name: {
      type: String
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    limit1: {
      type: Number
    },
    limit2: {
      type: Number
    },
    colors: {
      type: Array
    }
  },
  data: function() {
    return {
      radialChartOptions: null
    }
  },
  created() {
    this.radialChartOptions = {
      min: this.min,
      max: this.max,
      limit1: this.formatValue(this.limit1, this.max),
      limit2: this.formatValue(this.limit2, this.max),
      colors: this.colors,
      value_origin: this.value,
      chart: {
        height: 350,
        type: 'radialBar',
        offsetY: -30,
        name: this.name
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          dataLabels: {
            name: {
              fontSize: '25px',
              color: '#000000',
              offsetY: 0
            },
            value: {
              offsetY: 55,
              fontSize: '30px',
              color: '#000000',
              formatter: function(val, w) {
                return (w.config.value_origin || 0).toFixed(2)
              }
            }
          }
        }
      },
      fill: {
        colors: [
          function({ value, w }) {
            if (value <= w.config.limit1) {
              return w.config.colors[0]
            } else if (value <= w.config.limit2) {
              return w.config.colors[1]
            } else {
              return w.config.colors[2]
            }
          }
        ]
      },
      stroke: {
        dashArray: 3
      },
      labels: [this.value_text || '']
    }
  },
  methods: {
    formatValue(value, max) {
      let valor_formatado = value
      valor_formatado = (value * 100) / max
      return valor_formatado
    }
  }
}
</script>
